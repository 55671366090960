<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="setDrawer(!drawer)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />

    <v-spacer />

    <v-text-field
      :label="$t('search')"
      color="secondary"
      hide-details
      style="max-width: 250px;"
      v-model="searchKey"
    >
      <template
        v-if="$vuetify.breakpoint.mdAndUp"
        v-slot:append-outer
      >
        <v-btn
          class="mt-n2"
          elevation="1"
          fab
          small
          @click="doSearch()"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-text-field>

    <div class="mx-3" />

    <v-btn
      class="ml-2"
      min-width="0"
      text
      to="/"
    >
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn>

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
      style="z-index: 999 !important;background-color: white !important;" 
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-badge
            color="red"
            overlap
            bordered
          >
            <template v-slot:badge>
              <span>{{ totNotif }}</span>
            </template>

            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list
        v-if="listNotif.length > 0"
        :tile="false"
        nav
        height="50vH"
        style="z-index: 999 !important;background-color: white !important;color:black !important;" 
      >
        <div >
          <app-bar-item
            v-for="(n, i) in listNotif"
            :key="`item-${i}`"
            style="z-index: 999 !important;background-color: white !important;color:black !important;" 
          >
            <v-list-item-title>
              <span v-html="n.messages" @click="viewProcess(n.id, n.datasurat)"></span>
            </v-list-item-title>
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>

    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :close-on-click="true"
      :nudge-width="200"
      offset-y
      transition="slide-y-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          class="ml-2"
          dark
          v-on="on"
        >
          <div class="v-avatar" style="height: 20px; min-width: 20px; width: 20px;"><img :src="photo" ></div>
        </v-btn>
      </template>

          <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <img :src="photo" v-on="on" @click="isPhotoProfile=true">
                </template>
                <span>Change Photo</span>
              </v-tooltip>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ name }}</v-list-item-title>
              <v-list-item-subtitle>{{ email }} </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title><div class="subtitle-1">Role Jabatan:</div></v-list-item-title>
              <v-list-item-subtitle>
                <v-col
                  cols="12"
                  md="12"
                >
                <v-select
                    :items="roleItems"
                    v-model="selRole"
                    label="Pilih Role Jabatan"
                    item-value="value"
                    item-text="text"
                    outlined
                    small
                    v-on:change="changeRole"
                  ></v-select>
                </v-col>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-btn color="normal" @click='logOut' class="title-2">Logout</v-btn>
            <v-btn text @click="menu = false">Tutup</v-btn>
          </v-list-item>
        </v-list>

    </v-menu>

    <v-dialog v-model="isLoading" fullscreen>
        <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
            <v-layout justify-center align-center>
              <v-card
                width="70vW"
                class="pa-md-2 mx-lg-auto"
                outlined
              >
                <v-list-item three-line centered>
                  <v-list-item-content>
                    <div class="overline mb-4">Processing</div>
                    <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-progress-linear
                        indeterminate
                        color="yellow darken-2"
                      ></v-progress-linear>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>

            </v-layout>
        </v-container>
    </v-dialog>

    <v-dialog v-model="isPhotoProfile" persistent max-width="70%">
        <v-card>
          <v-card-title class="headline">Photo Profile</v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                  cols="12"
                  md="6"
                >
                <img class="text-center" :src="photo" width="200px">
              </v-col>
              <v-col
                  cols="12"
                  md="6"
                >
                  <v-file-input
                    v-model="attachment.file"
                    placeholder="Ganti Profil Foto"
                    label="File input"
                    show-size
                    single
                    prepend-icon="mdi-paperclip"
                    accept=".png,.jpg"
                    outlined
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip
                        small
                        label
                        color="primary"
                      >
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                  <v-alert
                    dense
                    outlined
                    type="error"
                    v-if="txtAlert.length > 0"
                  >
                    {{ txtAlert }}
                  </v-alert>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning darken-1" text @click="isPhotoProfile = false">Cancel</v-btn>
            <v-btn color="blue darken-1" @click="updatePhotoProfil()">Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar
          v-model="toast.show"
          :timeout="toast.timeout"
        >
          {{ toast.text }}
          <v-btn
            :color="toast.color"
            text
            @click="toast.show = false"
          >
            Close
          </v-btn>
        </v-snackbar>

  </v-app-bar>
</template>

<script>
  // Components
  import { VHover, VListItem } from 'vuetify/lib'

  // Utilities
  import { mapState, mapMutations } from 'vuex'
  import { _GLOBAL } from '@/api/server'
  import moment from 'moment'
  import secureStorage from '@/utils/secureStorage'
  import { getUserPosRoles } from '@/api/positionRoles'
  import { getNotifUser, updateNotifUser } from '@/api/notifuser'
  import { uploadAvatarUser, getActiveUser } from '@/api/admin'

  import { getListUsrPosActiveAll } from '@/api/hruserpositions'
  import { getALLHROrganization } from '@/api/hrorganization'
  import { getListPositionsActiveByHolding } from '@/api/hrpositions'

  export default {
    name: 'DashboardCoreAppBar',

    components: {
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default)
              },
            },
          })
        },
      },
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      isLoading: false,
      isPhotoProfile: false,
      listNotif: [],
      totNotif: 0,
      vtimeRefreshData: null,
      windowSize: {
        x: 0,
        y: 0
      },
      attachment: {
        name: '',
        file: null,
        url: ''
      },
      toast: { show: false, color:'green', text:'', timeout: 2000 },
      txtAlert: '',
      photo: '',
      idAvatar: 0,
      userId: '',
      name: '',
      email: '',
      user: {},
      timestamp: '',
      roleItems: [],
      selRole: 0,
      userpos: [],
      menu: false,
      searchKey: ''
    }),

    computed: {
      ...mapState(['drawer']),
      computedValue: function(){
          return this.timestamp;
      }
    },
    destroyed () {
      clearInterval(this.vtimeRefreshData)
    },

    mounted() {
      var vm = this
      vm.onResize()
      // console.log('AppDrawer')

      let user = secureStorage.getItem('user')
      var token = secureStorage.getItem('jwt')

      if (user !== null) {
        vm.user = user
        vm.name = vm.user.fullname
        vm.email = vm.user.email
        vm.userId = vm.user.id
        vm.userNik = vm.user.nik

        if (vm.user.avatar !== null) {
          vm.photo = _GLOBAL.URLBACKEND + vm.user.avatar.url
          vm.idAvatar = vm.user.avatar.id
        }
        else {
          vm.photo = _GLOBAL.URLBACKEND + '/images/iconman.png?imageView2/1/w/80/h/80'
          if (vm.user.gender === 'Wanita') {
            vm.photo = _GLOBAL.URLBACKEND + '/images/iconwoman.png?imageView2/1/w/80/h/80'
          }
        }
      }
      
      vm.userpos = secureStorage.getItem('userpos')
      if(!vm.userpos){
        getUserPosRoles(token, vm.user.id).then(response => {
          vm.userpos = response.data
          secureStorage.setItem('userpos',response.data)
          vm.parseRole ()
        }).catch(error => {
          console.log(error)
        })
      } 
      else {
        vm.parseRole ()
      }

      var last = secureStorage.getItem('lastupdate')
      var now = moment()
      if(last){
        var diff = now.diff(last, 'minutes')
        console.log('diff', diff)
        if(diff > 30){
          vm.reloadData()
        }else{
          vm.reloadData()
        }
      }else{
        vm.reloadData()
      }
      
      
      //Start Timer
      vm.vtimeRefreshData = setInterval(function(){
        console.log('refresh notifications')
        vm.reloadNotif(vm.selRole)
      }, 60000*30)

    },

    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
      onResize () {
        this.windowSize = { x: window.innerWidth, y: window.innerHeight }
        // console.log(this.windowSize)
        if(this.windowSize.x <= 400){
          this.setDrawer(false)
        }
      },
      reloadNotif(idupos){
        const vm = this
        if(idupos == 0) return false
        // console.log('reloadNotif')

        vm.listNotif = []
        getNotifUser(idupos).then(response => {
          var data = response.data
          // console.log('getNotifUser', data)
          if (data.length > 0) {
            vm.totNotif = data.length  
          }
          vm.listNotif = data
        }).catch(err => {
          console.log(err)
        })

      },

      reloadData(){
        const vm = this

        console.log('reload Data')
        secureStorage.setItem('lastupdate', moment())

        vm.arrCurrRole = secureStorage.getItem('currRole')
        // console.log('currRole ',vm.arrCurrRole)

        var holding = ''
        if(vm.arrCurrRole){
          holding = vm.arrCurrRole.hrorganization.holding
          getListPositionsActiveByHolding(holding).then(response => {
            secureStorage.setItem('posall',response.data)
          });
        }
        console.log('holding ',holding)

        getListUsrPosActiveAll(holding).then(response => {
          // console.log(response.data)
          secureStorage.removeItem('userposall')
          secureStorage.setItem('userposall',response.data)
        }).catch(error => {
          console.log(error)
        })

        getActiveUser().then(response => {
          secureStorage.removeItem('userall')
          secureStorage.setItem('userall',response.data)
        }).catch(error => {
          console.log(error)
        })
      },
      
      viewProcess(idNotif, datasurat){
        const vm = this
        if(datasurat){
          // console.log('viewProcess', datasurat.id, datasurat.letterGUID)
          var uri = _GLOBAL.PORTALURL + '/#/surat/v/' + datasurat.letterGUID
          var open = window.open(uri, '_blank')
          if (open == null || typeof(open)=='undefined')
          alert("Turn off your pop-up blocker!\n\nWe try to open the following url:\n"+url);
        }

        var data = {
          isOpen: 1
        }
        updateNotifUser(data, idNotif).then(response => {
          vm.listNotif = vm.listNotif.filter(i=>i.id != idNotif)
        })
      },
      beforeFileUpload (file) {

        var isFormatOK = false
        if (file.type === 'image/jpeg' || file.type === 'image/png') {
          isFormatOK = true
        }
        const isLt2M = file.size / 1024 / 1024 < 1
        if (!isFormatOK) {
          console.log('Avatar picture must be JPG/PNG format!')
        }
        if (!isLt2M) {
          console.log('Avatar picture size can not exceed 1MB!')
        }
        return isFormatOK && isLt2M

      },
      updatePhotoProfil (){
        const vm = this

        var checked = vm.beforeFileUpload(vm.attachment.file)
        // console.log(checked)
        if(!checked){
          vm.txtAlert = 'File Foto tidak boleh lebih dari 1 MB.'
          return false
        }

        // console.log(vm.attachment)
        if (vm.attachment.file !== null) {
          vm.isLoading = true
          var refID = vm.idAvatar
          uploadAvatarUser(refID, vm.attachment.file).then(responseG => {
            var respG = responseG.data
            // console.log(respG[0])
            vm.isLoading = false
            /**Update to user storage for new avatar */
            var user = secureStorage.getItem('user')
            if(user){
              user['avatar'] = respG[0]
            }
            secureStorage.setItem('user',user)

            vm.isPhotoProfile = false
            vm.toast = {
              show: true, color:'green', text: 'Upload Foto Profil BERHASIL', timeout: 2000
            }
            
          }).catch(err => {
            console.log(err)
            vm.txtAlert = 'Upload Foto Profil GAGAL, silakan dicoba kembali.'
          })
        }else{
          vm.txtAlert = 'Input Foto belum dipilih!'
        }

        vm.isLoading = false
      },
      logOut () {
        // console.log('logout')
        this.$store.state.user = null
        secureStorage.removeItem('user')
        secureStorage.removeItem('jwt')
        secureStorage.removeItem('userpos')
        secureStorage.removeItem('orgall')
        secureStorage.removeItem('userall')
        secureStorage.removeItem('userposall')
        secureStorage.removeItem('currRole')
        secureStorage.removeItem('posall')

        this.$router.push('/login')
        // window.location.href = "/#/login"
      },
      parseRole () {
        const vm = this
        
        var currrole = secureStorage.getItem('currRole')
        vm.selRole = 0
        if (currrole){
          vm.selRole = currrole.id
          vm.reloadNotif(vm.selRole)
        }
        if (vm.userpos) {
          vm.roleItems = []
          vm.userpos.forEach (element => {
            if (element.hrposition) {
              var prefix = ''
              if(element.prefix === null){ 
                prefix = ''
              }else{
                prefix = element.prefix + ' '
              }
              
              var tmp = {value: element.id, text: prefix + element.hrposition.pos_name}
              vm.roleItems.push(tmp)
            }
          })
        }
      },
      changeRole () {
        var arrCurrRole = this.userpos.find(x => x.id === this.selRole)
        console.log('changeRole ',arrCurrRole)
        secureStorage.setItem('currRole', arrCurrRole)
        this.isLoading = true
        setTimeout(() => location.reload(), 1000)
        
      },
      doSearch(){
        const fixedEncodeURIComponent = (str) => {
          return encodeURIComponent(str).replace(/[!'()*]/g, (c) => {
            return '%' + c.charCodeAt(0).toString(16)
          })
        }
        
        var strSearch = fixedEncodeURIComponent(this.searchKey)
        // console.log(strSearch)
        if(strSearch.length > 3){
          this.$router.push('/search/v/' + strSearch)
          this.searchKey = ''
        }else{
          console.log('search string below 3 char')
        }
      }
    },
  }
</script>
